const getCookie = (name: string) => {
  let cookie = document.cookie;
  if (document.cookie != '') {
    let cookie_array = cookie.split('; ');
    for (let index in cookie_array) {
      let cookie_name = cookie_array[index].split('=');
      if (cookie_name[0] == name) {
        return true;
      }
    }
  }
  return false;
};

export default getCookie;
