import { initialProps } from '../../../constants';
import { LiveInfosProps, ThemeProps } from '../../../types';
import downloadFont from '../../../utils/downloadFont';
import getElements from '../../../utils/getElement';
import moveViewerPage from '../../../utils/moveViewerPage';
import setProps from '../../../utils/setProps';

class Theme2 extends HTMLElement {
  private props: ThemeProps;

  constructor() {
    super();

    this.props = { ...initialProps };

    downloadFont(
      'https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@700&display=swap',
    );
  }

  render() {
    this.attachShadow({ mode: 'open' });

    let wrapper = document.createElement('div');
    const style = document.createElement('style');

    style.textContent = `
      /* 모바일 : 데스크탑 = 1:1.39 비율 */

      #layout {
        --unit-size: 10px;
        background-clip: content-box, border-box;
        background-origin: border-box;
        border-radius: 1000px;
        border: calc(0.3 * var(--unit-size)) solid transparent;
        bottom: 16px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
        font-size: var(--unit-size);
        height: calc(7.2 * var(--unit-size));
        letter-spacing: 0;
        position: fixed;
        right: 16px;
        width: calc(7.2 * var(--unit-size));
        z-index: 500;
      }

      @media (min-width: 480px) {
        #layout {
          --unit-size: 13.9px;
          bottom: 40px;
          right: 40px;
        }
      }

      #layer {
        position: relative;
        width: 100%;
        height: 100%;
      }

      #event-container {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 1000px;
        bottom: 50%;
        cursor: pointer;
        height: calc(6.4 * var(--unit-size));
        left: 50%;
        position: absolute;
        transform: translate(-50%, 50%);
        width: calc(6.4 * var(--unit-size));
      }

      #bottom-tag {
        align-items: center;
        border-radius: 1000px;
        bottom: calc(-1 * 0.4 * var(--unit-size));
        color: white;
        display: flex;
        font-size: calc(1.2 * var(--unit-size));
        font-weight: 700;
        justify-content: center;
        left: 50%;
        line-height: 100%;
        padding: calc(0.4 * var(--unit-size)) calc(0.8 * var(--unit-size));
        position: absolute;
        transform: translateX(-50%);
        width: calc(5.4 * var(--unit-size));
        cursor: pointer;
      }

      #play-btn {
        height: calc(3 * var(--unit-size));
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: calc(3 * var(--unit-size));
        cursor: pointer;
      }
    `;

    wrapper.innerHTML = `
      <aside id="layout"  style="background-image: linear-gradient(#fff, #fff),
          ${
            this.props.status === 'LIVE'
              ? 'linear-gradient(180deg, #ff9900 0%, #f21651 100%)'
              : 'linear-gradient(180deg, #FFC501 0%, #FF7C26 100%)'
          };">
        <div id='layer'>
            <div     
            id="event-container"
            style="background-image: url(${this.props.thumbnailUrl})"></div>
            <div id='bottom-tag' style='
            background: ${
              this.props.status === 'LIVE'
                ? 'linear-gradient(143.47deg, #FF7628 1.58%, #F21651 78.72%)'
                : 'linear-gradient(134.81deg, #FFC501 -21.75%, #FF8023 74.92%)'
            };'>
              ${this.props.status === 'LIVE' ? 'LIVE' : 'SOON'}
            </div>
            <img src="https://firebasestorage.googleapis.com/v0/b/camerafi-live-han.appspot.com/o/shopify_widget_img%2Fwdiget_2_play_icon.svg?alt=media&token=c2d5c6ab-75bf-4775-8b99-7a1c7694aa91" id='play-btn'/> 
        </div>
    </aside>
      `;

    if (this.shadowRoot) {
      this.shadowRoot.appendChild(style);
      this.shadowRoot.appendChild(wrapper);
    }
  }

  connectedCallback() {
    const shop = this.getAttribute('shop');
    const { thumbnailUrl, status, id } = JSON.parse(
      this.getAttribute('liveEventInfo') as string,
    );

    setProps(status, this.props, {
      thumbnailUrl,
    });

    this.render();

    if (this.shadowRoot) {
      const { layout } = getElements(this.shadowRoot);

      layout.onclick = () => {
        if (shop) moveViewerPage(shop, id);
      };
    }
  }

  get liveEventInfo(): LiveInfosProps {
    return this.props._liveEventInfo;
  }
  set liveEventInfo(value) {
    this.props._liveEventInfo = value;
  }
  get shop(): string {
    return this.props._shop;
  }
  set shop(value) {
    this.props._shop = value;
  }
}

customElements.define('theme-theme2', Theme2);
