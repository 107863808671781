import { initialProps } from '../../../constants';
import { LiveInfosProps, ThemeProps } from '../../../types';
import downloadFont from '../../../utils/downloadFont';
import startTimer from '../../../utils/startTimer';
import moveViewerPage from '../../../utils/moveViewerPage';
import setCookie from '../../../utils/setCookie';
import getElements from '../../../utils/getElement';
import setProps from '../../../utils/setProps';

class Theme4 extends HTMLElement {
  private props: ThemeProps;

  constructor() {
    super();

    this.props = { ...initialProps };

    downloadFont(
      'https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap',
    );
  }

  render() {
    this.attachShadow({ mode: 'open' });

    let wrapper = document.createElement('div');
    const style = document.createElement('style');

    style.textContent = `
      /* 모바일 : 데스크탑 = 1:1.4 비율 */
      *{
        line-height:normal;
        letter-spacing: 0;
      }

      #layout {
        --unit-size: 14px;
        bottom: 16px;
        font-family: 'Noto Sans KR', sans-serif;
        height: calc(10.4 * var(--unit-size));
        line-height: normal;
        position: fixed;
        right: 16px;
        width: calc(32.8 * var(--unit-size));
        z-index: 500;
      }

      @media (max-width: 480px) {
        #layout {
          --unit-size: 10px;
          bottom:16px;
          left:50%;
          transform: translateX(-50%);
          transform-origin: bottom;
        }
      }

      #event-container {
        background: #ffffff;
        border-radius: calc(0.8 * var(--unit-size));
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        cursor: pointer;
        display: flex;
        height: calc(8 * var(--unit-size));
        overflow: hidden;
        width: 100%;
      }

      #right-container {
        width:calc(22.8 * var(--unit-size));
        padding: calc(1 * var(--unit-size));
      }

      #left-container {
        flex-shrink: 0;
        width: calc(8 * var(--unit-size));
      }

      #info {
        display: flex;
        align-items: center;
        margin-bottom: calc(1 * var(--unit-size));
      }

      #status {
        align-items: center;
        border-radius: 1000px;
        display: flex;
        font-size: calc(1 * var(--unit-size));
        font-style: normal;
        height: calc(1.6 * var(--unit-size));
        justify-content: center;
        margin-left: auto;
        min-width: calc(8.6 * var(--unit-size));
        text-align: center;
      }

      #sale {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;

        & > div:first-child {
          font-size: calc(1 * var(--unit-size));
          font-style: normal;
          font-weight: 400;
        }
        & > div:last-child {
          color: #ffffff;
          font-size: calc(2 * var(--unit-size));
          font-style: normal;
          font-weight: 300;
        }
      }

      #discount {
        color: #ffffff;
        font-size: calc(2.2 * var(--unit-size));
        font-style: normal;
        font-weight: 700;
        text-wrap: nowrap;
      }

      #date {
        color: #4a4a4a;
        display: inline-block;
        font-size: calc(1.2 * var(--unit-size));
        font-style: normal;
        font-weight: 500;
        margin-right: calc(0.4 * var(--unit-size));
      }

      #time {
        display: inline-block;
        font-size: calc(1.2 * var(--unit-size));
        font-style: normal;
        font-weight: 500;
        margin-right: calc(0.2 * var(--unit-size));
      }

      #timezone {
        color: rgba(74, 74, 74, 0.6);
        display: inline-block;
        font-size: calc(1 * var(--unit-size));
        font-style: normal;
        font-weight: 400;
      }

      #title {
        font-family: 'Noto Sans KR', sans-serif;
        color: rgba(0, 0, 0, 0.7);
        font-size: calc(1.4 * var(--unit-size));
        font-style: normal;
        font-weight: 400;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow-wrap: break-word;
        word-break: keep-all;
        line-height:130%;
      }

      #close-footer {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 0 calc(1 * var(--unit-size));
      }

      #dont-show-24-hours {
        align-items: center;
        color: #9e9e9e;
        display: flex;
        font-size: calc(1.2 * var(--unit-size));
        font-style: normal;
        font-weight: 400;
        padding: calc(0.6 * var(--unit-size)) 0;
        text-align: center;
        gap: calc(0.4 * var(--unit-size));
      }

      #close-icon {
        cursor: pointer;
        height: calc(1.05 * var(--unit-size));
        width: calc(1.05 * var(--unit-size));
      }
    `;

    wrapper.innerHTML = `
      <aside id="layout">
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap"
          rel="stylesheet"
        />
        <div id="event-container">
          <div
            id="left-container"
            style="background:${
              this.props.status === 'LIVE'
                ? 'linear-gradient(161.34deg, #FFB801 -10.56%, #FF0801 103.36%)'
                : 'linear-gradient(159.06deg, #85d6e4 -9.71%, #87dab0 100.95%)'
            }"
          >
            <div id="sale">
              <div
                style="color:${
                  this.props.status === 'LIVE' ? '#8B1802' : '#3D7472'
                }"
              >
                UP TO
              </div>
              <div id="discount">${this.props.discount}</div>
              <div>OFF</div>
            </div>
          </div>
          <div id="right-container">
            <div id="info">
              ${
                this.props.status === 'COMING SOON'
                  ? `<div id="date">${this.props.date}</div>`
                  : ''
              }
              <div
                id="time"
                style="color:${
                  this.props.status === 'LIVE' ? '#FF1902' : '#39ab9f'
                }"
              >
                ${this.props.time}
              </div>
              ${
                this.props.status === 'COMING SOON'
                  ? `<div id="timezone">${this.props.timezone}</div>`
                  : ''
              }
              <div
                id="status"
                style="background:${
                  this.props.status === 'LIVE' ? '#ff1902' : 'transparent'
                };
                  border:${
                    this.props.status === 'LIVE' ? 'none' : '1px solid #39AB9F'
                  };
                  color:${this.props.status === 'LIVE' ? 'white' : '#39AB9F'};
                  font-weight:${this.props.status === 'LIVE' ? '700' : '400'};
                  "
              >
                ${this.props.status}
              </div>
            </div>
            <div id="title">${this.props.eventTitle}</div>
          </div>
        </div>
        <footer id="close-footer">
          <div id="dont-show-24-hours">
            <styled-checkbox id="checkbox"></styled-checkbox>
            Don't show again for 24h
          </div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/camerafi-live-han.appspot.com/o/shopify_widget_img%2Fclose_icon_gray.png?alt=media&token=8aae62a6-949f-42aa-8657-a7f7af69ef0b&_gl=1*1twdplv*_ga*MTU5MjA3MTY2LjE2NzI2Mjg5NjY.*_ga_CW55HF8NVT*MTY4NTUxNzUyNS41NC4xLjE2ODU1MTc3MzAuMC4wLjA."
            alt="close"
            class="x-icon"
            id="close-icon"
          />
        </footer>
      </aside>
    `;

    if (this.shadowRoot) {
      this.shadowRoot.appendChild(style);
      this.shadowRoot.appendChild(wrapper);
    }
  }

  connectedCallback() {
    const shop = this.getAttribute('shop');
    let {
      actualStartTime,
      discountCodes,
      id,
      scheduledStartTime,
      status,
      thumbnailUrl,
      title,
    } = JSON.parse(this.getAttribute('liveEventInfo') as string);

    setProps(status, this.props, {
      scheduledStartTime,
      thumbnailUrl,
      title,
      discountCodes,
    });

    this.render();

    if (this.shadowRoot) {
      const {
        checkbox,
        closeFooter,
        closeIcon,
        discountContainer,
        eventContainer,
        layout,
        timeElement,
      } = getElements(this.shadowRoot);

      if (window.innerWidth >= 480 && discountContainer.offsetWidth > 112) {
        discountContainer.style.transform = `scale(${
          102 / discountContainer.offsetWidth
        })`;
      } else if (
        window.innerWidth < 480 &&
        discountContainer.offsetWidth > 80
      ) {
        discountContainer.style.transform = `scale(${
          70 / discountContainer.offsetWidth
        })`;
      }
      if (status === 'live') {
        startTimer(actualStartTime, timeElement);
      }

      closeIcon.addEventListener('click', (event) => {
        if (checkbox.checked) setCookie('modal-expire', 'modal-expire', 1);
        layout.style.display = 'none';
      });

      closeFooter.onclick = (event) => {
        event.stopPropagation();
      };

      eventContainer.onclick = () => {
        if (shop) moveViewerPage(shop, id);
      };
    }
  }

  get liveEventInfo(): LiveInfosProps {
    return this.props._liveEventInfo;
  }
  set liveEventInfo(value) {
    this.props._liveEventInfo = value;
  }
  get shop(): string {
    return this.props._shop;
  }
  set shop(value) {
    this.props._shop = value;
  }
}

customElements.define('theme-theme4', Theme4);
