class StyledCheckbox extends HTMLElement {
  checked: boolean;
  checkedCheckBox: string;
  unCheckedCheckBox: string;

  constructor() {
    super();
    this.checked = true;

    this.attachShadow({ mode: 'open' });
    const style = document.createElement('style');
    style.textContent = `
      input[type='checkbox'] {
        display: none;
      }

      input[type='checkbox'] + label {
        cursor: pointer;
        background: url('https://firebasestorage.googleapis.com/v0/b/camerafi-live-han.appspot.com/o/shopify_widget_img%2Frectangular_gray.svg?alt=media&token=bd11d579-50e2-4cfe-87ea-15664b20528d')
          no-repeat 0 0px / contain;
        display: block;
        height: calc(1.4 * var(--unit-size));
        width: calc(1.4 * var(--unit-size));
      }

      input[type='checkbox']:checked + label {
        background: url('https://firebasestorage.googleapis.com/v0/b/camerafi-live-han.appspot.com/o/shopify_widget_img%2Fcheckbox_gray.svg?alt=media&token=0367c673-f91d-4afd-920b-2da3f38ca4fe')
          no-repeat 0 0px / contain;
      }

      label::after {
        content: url('https://firebasestorage.googleapis.com/v0/b/camerafi-live-han.appspot.com/o/shopify_widget_img%2Fcheckbox_gray.svg?alt=media&token=0367c673-f91d-4afd-920b-2da3f38ca4fe');
        height: 0;
        overflow: hidden;
        width: 0;
        visibility: hidden;
        z-index: -1;
      }
    `;

    const wrapper = document.createElement('div');
    wrapper.id = 'wrapper';
    wrapper.innerHTML = `<input type="checkbox" id="checkbox" value=${this.checked}></input>
        <label for="checkbox"></label> `;
    this.shadowRoot.appendChild(style);
    this.shadowRoot.appendChild(wrapper);
  }
}

customElements.define('styled-checkbox', StyledCheckbox);

export {};
