const getShop = () => {
  if (process.env.NODE_ENV === 'production') {
    const permanentDomain =
      document.getElementById('permanent-domain').innerText;
    return permanentDomain;
  }
  return process.env.DEV_SHOP;
};

export default getShop;
