import { ThemeProps } from './types';

export const initialProps: ThemeProps = {
  _liveEventInfo: undefined,
  _shop: undefined,
  date: undefined,
  discount: undefined,
  eventTitle: undefined,
  status: undefined,
  thumbnailUrl: undefined,
  time: '00:00:00',
  timezone: undefined,
};
