const getHMS = (time: number) => {
  if (time) {
    const temp = Math.ceil(time / 1000);
    const second = temp % 60;
    const minute = ((temp - second) / 60) % 60;
    const hour = ((temp - second) / 60 - minute) / 60;
    return `${hour <= 9 ? `0${hour}` : hour}:${
      minute <= 9 ? `0${minute}` : minute
    }:${second <= 9 ? `0${second}` : second}`;
  } else {
    return '00:00:00';
  }
};

const startTimer = (startsAt: number, element: HTMLElement) => {
  let onLineProgressingTime =
    new Date().getTime() - new Date(startsAt).getTime();

  setInterval(() => {
    onLineProgressingTime += 1000;

    element.innerText = `${getHMS(onLineProgressingTime)}`;
  }, 1000);
};

export default startTimer;
