import { initialProps } from '../../../constants';
import { LiveInfosProps, ThemeProps } from '../../../types';
import downloadFont from '../../../utils/downloadFont';
import startTimer from '../../../utils/startTimer';
import moveViewerPage from '../../../utils/moveViewerPage';
import setCookie from '../../../utils/setCookie';
import getElements from '../../../utils/getElement';
import setProps from '../../../utils/setProps';

class Theme5 extends HTMLElement {
  private props: ThemeProps;

  constructor() {
    super();

    this.props = { ...initialProps };

    downloadFont(
      'https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap',
    );
  }

  render() {
    this.attachShadow({ mode: 'open' });

    let wrapper = document.createElement('div');
    const style = document.createElement('style');

    style.textContent = `
      *{
        box-sizing: border-box;
        line-height: 1;
      }

      #layout {
        --unit-size: 10px;
        bottom: 20px;
        color: white;
        font-family: 'Noto Sans KR', sans-serif;
        height: calc(14.8 * var(--unit-size));
        line-height: initial;
        position: fixed;
        right: 20px;
        width: calc(26 * var(--unit-size));
        z-index: 500;
      }

      @media (min-width: 480px) {
        #layout {
          --unit-size: 16px;
          bottom: 16px;
          right: 16px;
        }
      }

      #event-container {
        background: #222222;
        border-bottom-left-radius: 1000px;
        border-top-left-radius: 1000px;
        cursor: pointer;
        display: flex;
        height: calc(10.2 * var(--unit-size));
        padding: calc(1.4 * var(--unit-size)) calc(0.8 * var(--unit-size));
        width: 100%;
      }

      #left-container {
        align-items: center;
        border-right: 1px dashed #ffffff54;
        display: flex;
        flex-shrink: 0;
        gap: calc(1.2 * var(--unit-size));
        justify-content: center;
        width: calc(7 * var(--unit-size));
      }

      #right-container {
        display: flex;
        flex-direction: column;
        padding-left: calc(0.6 * var(--unit-size));
      }

      #circle {
        border-radius: 1000px;
        height: calc(0.6 * var(--unit-size));
        width: calc(0.6 * var(--unit-size));
      }

      #status {
        font-size: calc(1.4 * var(--unit-size));
        font-weight: 700;
        writing-mode: vertical-rl;
      }

      #discount {
        align-items: center;
        display: flex;
        gap: calc(0.4 * var(--unit-size));
        transform-origin: top left;
      }

      #discount > span:first-child {
        display: inline-block;
        font-size: calc(1 * var(--unit-size));
        font-weight: 700;
      }

      #discount > span:nth-child(2) {
        font-size: calc(2.6 * var(--unit-size));
        font-weight: 700;
      }

      #discount > span:last-child {
        font-size: calc(2.6 * var(--unit-size));
        font-weight: 300;
      }

      #date {
        color: #ffffff;
        display: inline-block;
        font-size: calc(1 * var(--unit-size));
        font-style: normal;
        font-weight: 500;
        margin-right: calc(0.6 * var(--unit-size));
      }

      #time {
        font-size: calc(1 * var(--unit-size));
        font-weight: 500;
      }

      #time-info {
        color: #ffffff;
        display: flex;
        margin-top:auto;
      }

      #timezone {
        color: #ffffff;
        display: inline-block;
        font-size: calc(1 * var(--unit-size));
        font-style: normal;
        font-weight: 400;
      }

      #title {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        color: #aaaaaa;
        display: -webkit-box;
        font-size: calc(1.2 * var(--unit-size));
        font-weight: 400;
        line-height: normal;
        overflow-wrap: break-word;
        overflow: hidden;
        width: calc(17.4 * var(--unit-size));
        word-break: keep-all;
      }

      #close-footer {
        align-items: center;
        display: flex;
      }

      #dont-show-24-hours {
        align-items: center;
        color: #9e9e9e;
        cursor: pointer;
        display: flex;
        font-size: calc(1.2 * var(--unit-size));
        font-style: normal;
        font-weight: 400;
        gap: calc(0.4 * var(--unit-size));
        margin-left: auto;
        padding: calc(0.6 * var(--unit-size)) 0;
      }

      #close-icon {
        cursor: pointer;
        float: right;
        height: calc(1.05 * var(--unit-size));
        margin-bottom: calc(0.8 * var(--unit-size));
        width: calc(1.05 * var(--unit-size));
      }
    `;

    wrapper.innerHTML = `
      <aside id="layout">
        <img
          id="close-icon"
          src="https://firebasestorage.googleapis.com/v0/b/camerafi-live-han.appspot.com/o/shopify_widget_img%2Fclose_icon_gray.png?alt=media&token=8aae62a6-949f-42aa-8657-a7f7af69ef0b&_gl=1*1yb544m*_ga*MTU5MjA3MTY2LjE2NzI2Mjg5NjY.*_ga_CW55HF8NVT*MTY4NTUxMTgyOS41My4xLjE2ODU1MTE4NjEuMC4wLjA."
          alt="close widget"
        />
        <div id="event-container">
          <div id="left-container">
            <div
              style="background:${
                this.props.status === 'LIVE' ? '#F01940' : '#F59820'
              }"
              id="circle"
            ></div>
            <div
              id="status"
              style=${`color:${
                this.props.status === 'LIVE' ? '#F01940' : '#F59820'
              }`}
            >
              ${this.props.status}
            </div>
          </div>
          <div id="right-container">
            <div id="discount">
              <span>
                <div>UP</div>
                <div>TO</div>
              </span>
              <span>${this.props.discount}</span>
              <span>OFF</span>
            </div>
              <div id="title">${this.props.eventTitle}</div>
            <div id="time-info">
              ${
                this.props.status === 'SOON'
                  ? `<div id="date">${this.props.date}</div>`
                  : ''
              }
              <div id="time">${this.props.time}</div>
              ${
                this.props.status === 'SOON'
                  ? `<div id="timezone">${this.props.timezone}</div>`
                  : ''
              }
            </div>
          </div>
        </div>
          <footer id="close-footer">
            <div id="dont-show-24-hours">
            <styled-checkbox id='checkbox'></styled-checkbox>
              Don't show again for 24h
            </div>
          </footer>
        </aside>
      </div>
    `;

    if (this.shadowRoot) {
      this.shadowRoot.appendChild(style);
      this.shadowRoot.appendChild(wrapper);
    }
  }

  connectedCallback() {
    const shop = this.getAttribute('shop');
    let {
      actualStartTime,
      discountCodes,
      id,
      scheduledStartTime,
      status,
      thumbnailUrl,
      title,
    } = JSON.parse(this.getAttribute('liveEventInfo') as string);

    setProps(status, this.props, {
      scheduledStartTime,
      thumbnailUrl,
      title,
      discountCodes,
    });
    if (status === 'scheduled') {
      this.props.status = 'SOON';
    }

    this.render();

    if (this.shadowRoot) {
      const {
        checkbox,
        closeFooter,
        closeIcon,
        discountContainer,
        eventContainer,
        layout,
        timeElement,
      } = getElements(this.shadowRoot);

      if (window.innerWidth >= 480 && discountContainer.offsetWidth > 265) {
        discountContainer.style.transform = `scale(${
          250 / discountContainer.offsetWidth
        })`;
      } else if (
        window.innerWidth < 480 &&
        discountContainer.offsetWidth > 155
      ) {
        discountContainer.style.transform = `scale(${
          140 / discountContainer.offsetWidth
        })`;
      }

      if (status === 'live') {
        startTimer(actualStartTime, timeElement);
      }

      closeIcon.addEventListener('click', (event) => {
        if (checkbox.checked) setCookie('modal-expire', 'modal-expire', 1);
        if (event.stopPropagation) event.stopPropagation();
        layout.style.display = 'none';
      });

      closeFooter.onclick = (event) => {
        event.stopPropagation();
      };

      eventContainer.onclick = () => {
        if (shop) moveViewerPage(shop, id);
      };
    }
  }

  get liveEventInfo(): LiveInfosProps {
    return this.props._liveEventInfo;
  }
  set liveEventInfo(value) {
    this.props._liveEventInfo = value;
  }
  get shop(): string {
    return this.props._shop;
  }
  set shop(value) {
    this.props._shop = value;
  }
}

customElements.define('theme-theme5', Theme5);
