import { initialProps } from '../../../constants';
import { LiveInfosProps, ThemeProps } from '../../../types';
import downloadFont from '../../../utils/downloadFont';
import getElements from '../../../utils/getElement';
import moveViewerPage from '../../../utils/moveViewerPage';
import setCookie from '../../../utils/setCookie';
import setProps from '../../../utils/setProps';
import startTimer from '../../../utils/startTimer';

class Theme3 extends HTMLElement {
  private props: ThemeProps;

  constructor() {
    super();

    this.props = { ...initialProps };

    downloadFont(
      'https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap',
    );
  }

  render() {
    this.attachShadow({ mode: 'open' });

    let wrapper = document.createElement('div');
    const style = document.createElement('style');

    style.textContent = `
      *{
        line-height: 1;
      }

      #layout {
        --unit-size: 10px;
        background: #ffffff;
        border-radius: calc(1.4 * var(--unit-size)) calc(1.4 * var(--unit-size))
          0 0;
        bottom: 20px;
        box-shadow: 0px 4px 60px 0px #00000040;
        cursor: pointer;
        font-family: 'Noto Sans KR', sans-serif;
        height: calc(24.6 * var(--unit-size));
        letter-spacing: 0;
        position: fixed;
        right: 20px;
        width: calc(36 * var(--unit-size));
        z-index: 500;
      }

      #right-container {
        width: calc(20 * var(--unit-size));
      }

      @media (max-width: 480px) {
        #layout {
          bottom:0;
          left:50%;
          width:100vw;
          transform: translateX(-50%);
          transform-origin: bottom;
        }
      }

      @media (max-width: 480px) {
        #right-container {
          width: calc(100% - 122px);
        }
      }

      #event-container {
        box-sizing: border-box;
        display: flex;
        gap: calc(2.1 * var(--unit-size));
        height: calc(20.9 * var(--unit-size));
        padding: calc(1.6 * var(--unit-size));
        width: 100%;
      }

      #thumbnail {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: calc(0.8 * var(--unit-size));
        flex-shrink: 0;
        height: 100%;
        width: calc(10.1 * var(--unit-size));
      }

      #status {
        border-radius: 1000px;
        color: #ffffff;
        font-size: calc(1.4 * var(--unit-size));
        font-weight: 500;
        margin-bottom: calc(2.2 * var(--unit-size));
        padding: calc(0.5 * var(--unit-size)) calc(1.1 * var(--unit-size));
        width: fit-content;
      }

      #discount {
        color: #424242;
        font-size: calc(1.6 * var(--unit-size));
        font-weight: 400;
        margin-bottom: calc(0.6 * var(--unit-size));
        text-wrap: nowrap;
        transform-origin: left 50%;
      }

      #time {
        color: #222222;
        display: inline-block;
        font-size: calc(3.6 * var(--unit-size));
        font-weight: 700;
        margin-bottom: calc(1.4 * var(--unit-size));
      }

      #timezone {
        color: #222222;
        display: inline-block;
        font-size: calc(2 * var(--unit-size));
        font-weight: 700;
        margin-left: calc(0.6 * var(--unit-size));
      }

      #title {
        color: #616161;
        font-size: calc(1.6 * var(--unit-size));
        font-weight: 400;
        line-height: normal;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow-wrap: break-word;
        word-break: keep-all;
      }

      #close-footer {
        align-items: center;
        border-top: 1px solid #e0e0e0;
        display: flex;
        margin: 0 calc(1.6 * var(--unit-size));
      }

      #dont-show-24-hours {
        color: #9e9e9e;
        font-size: calc(1.2 * var(--unit-size));
        font-style: normal;
        font-weight: 400;
        padding: calc(0.6 * var(--unit-size)) 0;
        text-align: center;
        width: 100%;
      }

      #divider {
        background: #e0e0e0;
        height: calc(1.4 * var(--unit-size));
        width: 1px;
      }

      #close-icon {
        color: #424242;
        font-size: calc(1.2 * var(--unit-size));
        font-weight: 400;
        padding: calc(0.6 * var(--unit-size)) 0;
        text-align: center;
        width: 100%;
      }
    `;

    wrapper.innerHTML = `<aside id="layout">
      <div id="event-container">
        <div
          id="thumbnail"
          style="background-image: url('${this.props.thumbnailUrl}')"
        ></div>
        <div id="right-container">
          <div
            id="status"
            style="background:${
              this.props.status === 'LIVE'
                ? ' linear-gradient(112.89deg, #FFA401 5.34%, #FF0401 84.56%)'
                : 'linear-gradient(101.77deg, #85D5E3 20.14%, #86DAAF 67.08%)'
            }"
          >
            ${this.props.status}
          </div>
          <div id="discount">
            ${
              this.props.status === 'LIVE'
                ? `UP TO ${this.props.discount} OFF`
                : this.props.date
            }
          </div>
          <div id="time">${this.props.time}</div>
          ${
            this.props.timezone
              ? `<div id="timezone">${this.props.timezone}</div>`
              : ''
          }
          <div id="title">${this.props.eventTitle}</div>
        </div>
      </div>
      <footer id="close-footer">
        <div id="dont-show-24-hours">Don’t show again for 24 h</div>
        <div id="divider"></div>
        <div id="close-icon">CLOSE</div>
      </footer>
    </aside>`;

    if (this.shadowRoot) {
      this.shadowRoot.appendChild(style);
      this.shadowRoot.appendChild(wrapper);
    }
  }

  connectedCallback() {
    const shop = this.getAttribute('shop');
    const {
      actualStartTime,
      discountCodes,
      id,
      scheduledStartTime,
      status,
      thumbnailUrl,
      title,
    } = JSON.parse(this.getAttribute('liveEventInfo') as string);

    setProps(status, this.props, {
      scheduledStartTime,
      thumbnailUrl,
      title,
      discountCodes,
    });

    this.render();

    if (this.shadowRoot) {
      const {
        closeIcon,
        discountContainer,
        dontShow24Hours,
        eventContainer,
        layout,
        timeElement,
      } = getElements(this.shadowRoot);

      if (status === 'live') {
        startTimer(actualStartTime, timeElement);
        if (discountContainer.offsetWidth > 206) {
          discountContainer.style.transform = `scale(${
            206 / discountContainer.offsetWidth
          })`;
        }
      }

      dontShow24Hours.addEventListener('click', (event) => {
        layout.style.display = 'none';
        setCookie('modal-expire', 'modal-expire', 1);
        if (event.stopPropagation) event.stopPropagation();
      });

      closeIcon.addEventListener('click', (event) => {
        layout.style.display = 'none';
        if (event.stopPropagation) event.stopPropagation();
      });

      eventContainer.onclick = () => {
        if (shop) moveViewerPage(shop, id);
      };
    }
  }

  get liveEventInfo(): LiveInfosProps {
    return this.props._liveEventInfo;
  }
  set liveEventInfo(value) {
    this.props._liveEventInfo = value;
  }
  get shop(): string {
    return this.props._shop;
  }
  set shop(value) {
    this.props._shop = value;
  }
}

customElements.define('theme-theme3', Theme3);
