import { initialProps } from '../../../constants';
import { LiveInfosProps, ThemeProps } from '../../../types';
import downloadFont from '../../../utils/downloadFont';
import getElements from '../../../utils/getElement';
import moveViewerPage from '../../../utils/moveViewerPage';
import setCookie from '../../../utils/setCookie';
import setProps from '../../../utils/setProps';
import startTimer from '../../../utils/startTimer';

class Theme0 extends HTMLElement {
  private props: ThemeProps;

  constructor() {
    super();

    this.props = { ...initialProps };

    downloadFont(
      'https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap',
    );
  }

  render() {
    this.attachShadow({ mode: 'open' });

    let wrapper = document.createElement('div');
    const style = document.createElement('style');

    style.textContent = `
      /* 모바일 : 데스크탑 = 1:1.4 비율 */

      #layout {
        --unit-size: 10px;
        border-radius: calc(1.2 * var(--unit-size));
        bottom: 16px;
        box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.25);
        font-size: calc(1 * var(--unit-size));
        height: calc(32.4 * var(--unit-size));
        letter-spacing: 0;
        overflow: hidden;
        position: fixed;
        right: 16px;
        width: calc(18.2 * var(--unit-size));
        z-index: 500;
        font-family: 'Noto Sans KR', sans-serif;
      }

      @media (min-width: 480px) {
        #layout {
          --unit-size: 14px;
          bottom:20px;
          right:20px;
        }
      }

      #event-container {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
        height: 100%;
        position: relative;
        width: 100%;
      }

      #layer {
        background: rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        height: 100%;
        padding: calc(1 * var(--unit-size));
        width: 100%;
      }

      #top-content {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }

      #close-icon {
        height: calc(1.2 * var(--unit-size));
        object-fit: contain;
        width: calc(1.2 * var(--unit-size));
      }

      #status {
        align-items: center;
        border-radius: 1000px;
        color: white;
        display: flex;
        font-size: calc(1.2 * var(--unit-size));
        font-weight: 700;
        justify-content: center;
        line-height: 100%;
        padding: calc(0.6 * var(--unit-size)) calc(0.8 * var(--unit-size));
        width: fit-content;
      }

      #middle-content {
        left: 0;
        padding: 0 calc(1 * var(--unit-size));
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
        width:100%;
        box-sizing: border-box;
      }

      #date {
        color: white;
        font-size: calc(1.6 * var(--unit-size));
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        margin-bottom: calc(1 * var(--unit-size));
      }

      #time-container {
        align-items: bottom;
        display: flex;
        gap: calc(0.5 * var(--unit-size));
        margin-bottom: calc(2.8 * var(--unit-size));
      }

      #time {
        color: #ffffff;
        font-size: calc(3.2 * var(--unit-size));
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
      }

      #timezone {
        align-self: end;
        color: #ffffff;
        font-size: calc(2 * var(--unit-size));
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
      }

      #title {
        border-left: 1px solid white;
        color: #ffffff;
        font-size: calc(1.2 * var(--unit-size));
        font-style: normal;
        font-weight: 700;
        line-height: calc(1.5 * var(--unit-size));
        padding-left: calc(0.9 * var(--unit-size));
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow-wrap: break-word;
        word-break: keep-all;
      }

      #close-footer {
        align-items: center;
        background: rgba(68, 68, 68, 0.5);
        bottom: 0;
        box-sizing: border-box;
        cursor: default;
        display: flex;
        gap: calc(0.8 * var(--unit-size));
        height: calc(4 * var(--unit-size));
        left: 0;
        padding: calc(1 * var(--unit-size));
        position: absolute;
        width: 100%;
        z-index: 2;
      }

      #dont-show-24-hours {
        color: #ffffff;
        flex-grow: 0;
        flex: none;
        font-size: calc(1 * var(--unit-size));
        font-style: normal;
        font-weight: 400;
        letter-spacing: calc(-0.05 * var(--unit-size));
        opacity: 0.4;
        order: 1;
      }
    `;

    wrapper.innerHTML = `
      <aside id="layout">
        <div
          id="event-container"
          style="background-image: url(${this.props.thumbnailUrl})"
        >
          <div id="layer">
            <div id="top-content">
              <div
                id="status"
                style="background:${
                  this.props.status === 'LIVE' ? '#F01940' : '#F59820'
                }"
              >
                ${this.props.status}
              </div>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/camerafi-live-han.appspot.com/o/shopify_widget_img%2Fx_icon.svg?alt=media&token=14994fa7-1517-4a12-8f34-9ece50042bfe"
                alt="close"
                id="close-icon"
              />
            </div>
            <div id="middle-content">
              ${
                this.props.date ? `<div id="date">${this.props.date}</div>` : ''
              }
              <div id="time-container">
                <div id="time">${this.props.time}</div>
                ${
                  this.props.timezone
                    ? `<div id="timezone">${this.props.timezone}</div>`
                    : ''
                }
              </div>
              <div id="title">${this.props.eventTitle}</div>
            </div>
          </div>
          <footer id="close-footer">
              <styled-checkbox id='checkbox'></styled-checkbox>
            <div id="dont-show-24-hours">Don’t show again for 24 hours</div>
          </footer>
        </div>
      </aside>
    `;

    if (this.shadowRoot) {
      this.shadowRoot.appendChild(style);
      this.shadowRoot.appendChild(wrapper);
    }
  }

  connectedCallback() {
    const shop = this.getAttribute('shop');
    const {
      actualStartTime,
      id,
      scheduledStartTime,
      status,
      thumbnailUrl,
      title,
    } = JSON.parse(this.getAttribute('liveEventInfo') as string);

    setProps(status, this.props, {
      scheduledStartTime,
      thumbnailUrl,
      title,
    });

    this.render();

    if (this.shadowRoot) {
      const {
        checkbox,
        closeFooter,
        closeIcon,
        dontShow24Hours,
        eventContainer,
        layout,
        timeElement,
      } = getElements(this.shadowRoot);

      if (status === 'live') {
        startTimer(actualStartTime, timeElement);
      }

      checkbox.addEventListener('click', () => {
        const label = this.shadowRoot
          .querySelector('#checkbox')
          .shadowRoot.querySelector('label') as HTMLLabelElement;
        if (checkbox.checked) {
          closeFooter.style.background = 'rgba(22, 22, 22, 0.5)';
          dontShow24Hours.style.opacity = '100%';
          label.style.filter = 'brightness(0) invert(1)';
        } else {
          closeFooter.style.background = 'rgba(68, 68, 68, 0.5)';
          dontShow24Hours.style.opacity = '40%';
          label.style.filter =
            'invert(67%) sepia(0%) saturate(16%) hue-rotate(286deg) brightness(95%) contrast(85%)';
        }
      });

      closeIcon.addEventListener('click', (event) => {
        layout.style.display = 'none';
        if (checkbox.checked) setCookie('modal-expire', 'modal-expire', 1);
        if (event.stopPropagation) event.stopPropagation();
      });

      closeFooter.onclick = (event) => {
        event.stopPropagation();
      };

      eventContainer.onclick = () => {
        if (shop) moveViewerPage(shop, id);
      };
    }
  }

  get liveEventInfo(): LiveInfosProps {
    return this.props._liveEventInfo;
  }
  set liveEventInfo(value) {
    this.props._liveEventInfo = value;
  }
  get shop(): string {
    return this.props._shop;
  }
  set shop(value) {
    this.props._shop = value;
  }
}

customElements.define('theme-theme0', Theme0);
