const moveViewerPage = (shop: string, id: string) => {
  let element = document.createElement('a');
  element.setAttribute(
    'href',
    `https://${shop}/apps/camerafi-live/events/${id}`,
  );
  element.setAttribute('target', '_parent');
  element.click();
};

export default moveViewerPage;
