import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import advanced from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

const getFormattedDate = (date: string) => {
  const yourTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return `${dayjs(date).tz(yourTz).format('MMM')} ${dayjs(date)
    .tz(yourTz)
    .format('DD, (ddd)')}`.toUpperCase();
};

export default getFormattedDate;
