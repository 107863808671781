import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import advanced from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

const getFormattedTime = (time: string) => {
  const yourTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return dayjs(time).tz(yourTz).format('HH:mm').toUpperCase();
};

export default getFormattedTime;
