import { DiscountCodesType, ThemeProps } from '../types';
import getMaxDiscount from './getMaxDiscount';
import getFormattedDate from './getFormattedDate';
import getFormattedTime from './getFormattedTime';
import getTimezone from './getTimezone';

const setProps = (
  status: 'live' | 'scheduled',
  props: ThemeProps,
  eventInfo?: {
    scheduledStartTime?: string;
    thumbnailUrl?: string;
    title?: string;
    discountCodes?: DiscountCodesType[];
  },
) => {
  props.discount = getMaxDiscount(eventInfo.discountCodes);

  props.eventTitle = eventInfo.title;
  props.thumbnailUrl = eventInfo.thumbnailUrl;

  if (status === 'live') {
    props.date = null;
    props.status = 'LIVE';
    props.timezone = null;
  } else if (status === 'scheduled') {
    props.date = getFormattedDate(eventInfo.scheduledStartTime);
    props.status = 'COMING SOON';
    props.time = getFormattedTime(eventInfo.scheduledStartTime);
    props.timezone = getTimezone();
  }
};

export default setProps;
