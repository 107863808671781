import { DiscountCodesType } from '../types';

type MaxDiscountInfoType = {
  amount?: number;
  discountType?: 'percentage' | 'fixed_amount';
};

const getMaxDiscount = (discountInfo: DiscountCodesType[]) => {
  let maxDiscountAmount = 0;
  let maxDiscountInfo: MaxDiscountInfoType = {};
  if (discountInfo === undefined || discountInfo?.length === 0) return '-%';

  discountInfo.forEach((discount) => {
    const { discountType, fixedAmount, percentage, shopifyProductVariants } =
      discount;

    const mostExpensivePrice = Math.max(
      ...shopifyProductVariants.map((item) => Number(item.price)),
    );

    let discountAmount = 0;

    if (discountType === 'fixed_amount' && fixedAmount) {
      discountAmount = parseFloat(fixedAmount);
    } else if (discountType === 'percentage' && percentage) {
      discountAmount = (mostExpensivePrice * percentage) / 100;
    }

    if (discountAmount > maxDiscountAmount) {
      maxDiscountAmount = discountAmount;
      maxDiscountInfo = {
        amount: discountType === 'fixed_amount' ? discountAmount : percentage,
        discountType,
      };
    }
  });

  const currency = discountInfo[0].shopifyProductVariants[0].currency;

  return maxDiscountInfo?.discountType === 'fixed_amount'
    ? `${getFormattedAmount(maxDiscountInfo?.amount, currency)}`
    : `${maxDiscountInfo?.amount}%`;
};

const getFormattedAmount = (amount: number, currency: string) =>
  new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency,
  }).format(amount);

export default getMaxDiscount;
