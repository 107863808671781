const getElements = (shadowRoot: ShadowRoot) => {
  const checkbox = shadowRoot
    .querySelector('#checkbox')
    ?.shadowRoot?.querySelector('input') as HTMLInputElement;
  const closeFooter = shadowRoot.querySelector('#close-footer') as HTMLElement;
  const closeIcon = shadowRoot.querySelector('#close-icon') as HTMLElement;
  const discountContainer = shadowRoot.querySelector(
    '#discount',
  ) as HTMLElement;
  const dontShow24Hours = shadowRoot.querySelector(
    '#dont-show-24-hours',
  ) as HTMLElement;
  const eventContainer = shadowRoot.querySelector(
    '#event-container',
  ) as HTMLElement;
  const layout = shadowRoot.querySelector('#layout') as HTMLElement;
  const statusLabel = shadowRoot.querySelector('#status-label') as HTMLElement;
  const timeElement = shadowRoot.querySelector('#time') as HTMLDivElement;

  return {
    checkbox,
    closeFooter,
    closeIcon,
    discountContainer,
    dontShow24Hours,
    eventContainer,
    layout,
    statusLabel,
    timeElement,
  };
};

export default getElements;
