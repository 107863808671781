import { initialProps } from '../../../constants';
import { LiveInfosProps, ThemeProps } from '../../../types';
import downloadFont from '../../../utils/downloadFont';
import getElements from '../../../utils/getElement';
import moveViewerPage from '../../../utils/moveViewerPage';
import setCookie from '../../../utils/setCookie';
import setProps from '../../../utils/setProps';
import startTimer from '../../../utils/startTimer';

class Theme1 extends HTMLElement {
  private props: ThemeProps;

  constructor() {
    super();

    this.props = { ...initialProps };

    downloadFont(
      'https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap',
    );
  }

  render() {
    this.attachShadow({ mode: 'open' });

    let wrapper = document.createElement('div');
    const style = document.createElement('style');

    style.textContent = `
      #layout {
        --unit-size: 10px;
        border-radius: calc(1.25 * var(--unit-size));
        bottom: 16px;
        box-shadow: 0px 4px 10px 0px #00000040;
        font-family: 'Noto Sans KR', sans-serif;
        height: calc(21.45 * var(--unit-size));
        letter-spacing: 0;
        overflow: hidden;
        position: fixed;
        right: 16px;
        width: calc(22.5 * var(--unit-size));
        z-index: 500;
      }

      @media (min-width: 480px) {
        #layout {
          --unit-size: 15.8px;
          bottom: 20px;
          right: 20px;
        }
      }

      #event-container {
        background-origin: padding-box;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: calc(18.125 * var(--unit-size));
        position: relative;
      }

      #layer {
        background: rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        cursor: pointer;
        height: 100%;
        padding: calc(3.25 * var(--unit-size)) calc(1.875 * var(--unit-size)) 0
          calc(1.875 * var(--unit-size));
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
      }

      #title {
        font-style: normal;
        font-weight: 500;
        font-size: calc(1.2 * var(--unit-size));
        line-height: calc(1.5 * var(--unit-size));
        color: #ffffff;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp:2;
        -webkit-box-orient:vertical;
        overflow-wrap: break-word;
        word-break: keep-all;
      }

      #timezone {
        font-style: normal;
        font-weight: 700;
        font-size: calc(1.2 * var(--unit-size));
        line-height: 150%;
        display: inline-block;
        color: #ffffff;
      }

      #status-label {
        align-items: center;
        background: #11111173;
        border-radius: 1000px;
        color: white;
        display: flex;
        font-size: calc(1 * var(--unit-size));
        font-weight: 400;
        gap: calc(0.625 * var(--unit-size));
        line-height: 120%;
        margin-bottom: calc(1.25 * var(--unit-size));
        padding: calc(0.25 * var(--unit-size)) calc(0.875 * var(--unit-size));
        width: fit-content;
        text-shadow: none;
      }

      #time {
        color: #ffffff;
        font-size: calc(3 * var(--unit-size));
        font-style: normal;
        font-weight: 700;
        letter-spacing: -0.01em;
        line-height: normal;
        margin-bottom: calc(1.25 * var(--unit-size));
        display: inline-block;
        margin-right: calc(0.5 * var(--unit-size));
      }

      #date {
        color: #ffffff;
        font-size: calc(1 * var(--unit-size));
        font-style: normal;
        font-weight: 700;
        height: calc(1.4 * var(--unit-size));
        margin-bottom: calc(0.5 * var(--unit-size));
      }

      #red-circle {
        background: #ec0303;
        border-radius: 50%;
        display: block !important;
        height: calc(0.5 * var(--unit-size));
        width: calc(0.5 * var(--unit-size));
      }

      #orange-circle {
        background: #ffb84e;
        border-radius: 50%;
        display: block;
        height: calc(0.5 * var(--unit-size));
        width: calc(0.5 * var(--unit-size));
      }

      #close-footer {
        padding: calc(0.8125 * var(--unit-size)) calc(0.875 * var(--unit-size));
        background: #fbfbfb;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: default;
      }

      #dont-show-24-hours {
        color: rgba(0, 0, 0, 0.75);
        cursor: pointer;
        font-size: calc(1 * var(--unit-size));
        font-style: normal;
        font-weight: 400;
      }

      #close-icon {
        cursor: pointer;
        width: calc(1.5 * var(--unit-size));
        height: calc(1.5 * var(--unit-size));
      }
    `;

    wrapper.innerHTML = `<aside id="layout">
      <div
        id="event-container"
        style="background-image: url('${this.props.thumbnailUrl}')"
      >
        <div id="layer">
          <div id="status-label"></div>
          <div id="date">${this.props.date ? this.props.date : ''}</div>
          <div id="time">${this.props.time}</div>
          <div id="timezone">${
            this.props.timezone ? this.props.timezone : ''
          }</div>
          <div id="title">${this.props.eventTitle}</div>
        </div>
      </div>
      <footer id="close-footer">
        <div id="dont-show-24-hours">Don’t show again for 24 hours</div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/camerafi-live-han.appspot.com/o/shopify_widget_img%2Fclose-icon.svg?alt=media&token=15d11282-f855-49f4-b813-cbe4a133ae08"
          alt="close"
          id="close-icon"
        />
      </footer>
    </aside>`;

    if (this.shadowRoot) {
      this.shadowRoot.appendChild(style);
      this.shadowRoot.appendChild(wrapper);
    }
  }

  connectedCallback() {
    const shop = this.getAttribute('shop');
    const {
      actualStartTime,
      id,
      scheduledStartTime,
      status,
      thumbnailUrl,
      title,
    } = JSON.parse(this.getAttribute('liveEventInfo') as string);

    setProps(status, this.props, {
      scheduledStartTime,
      thumbnailUrl,
      title,
    });
    this.render();

    if (this.shadowRoot) {
      const {
        closeIcon,
        dontShow24Hours,
        eventContainer,
        layout,
        statusLabel,
        timeElement,
      } = getElements(this.shadowRoot);

      if (status === 'live') {
        startTimer(actualStartTime, timeElement);
      }

      if (status === 'live')
        statusLabel.innerHTML = `<div id="red-circle"></div> ${this.props.status}`;
      else if (status === 'scheduled')
        statusLabel.innerHTML = `<div id="orange-circle"></div> ${this.props.status}`;

      dontShow24Hours.addEventListener('click', () => {
        layout.style.display = 'none';
        setCookie('modal-expire', 'modal-expire', 1);
      });

      closeIcon.addEventListener('click', () => {
        layout.style.display = 'none';
      });

      eventContainer.onclick = () => {
        if (shop) moveViewerPage(shop, id);
      };
    }
  }

  get liveEventInfo(): LiveInfosProps {
    return this.props._liveEventInfo;
  }
  set liveEventInfo(value) {
    this.props._liveEventInfo = value;
  }
  get shop(): string {
    return this.props._shop;
  }
  set shop(value) {
    this.props._shop = value;
  }
}

customElements.define('theme-theme1', Theme1);
