import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import advanced from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

const getTimezone = () => {
  const yourTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return dayjs()
    .tz(yourTz)
    .format('zzz')
    .replace(/[^A-Z]/g, '');
};

export default getTimezone;
