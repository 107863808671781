import './components';
import { LiveInfosProps } from './types';
import getCookie from './utils/getCookie';
import getShop from './utils/getShop';

const fetchLiveEventInfo = async (
  url: string,
): Promise<LiveInfosProps | null> => {
  try {
    const res = await fetch(url);
    const result = await res?.json();
    return result;
  } catch (e) {
    console.error(e);
  }
};

const createThemeElement = (liveEventInfo: LiveInfosProps, shop: string) => {
  const theme = document.createElement(
    `theme-theme${liveEventInfo.templateId}`,
  );
  theme.setAttribute('liveEventInfo', JSON.stringify(liveEventInfo));
  theme.setAttribute('shop', shop);
  document.body.appendChild(theme);
};

const modal = async (
  shop: string = 'widget-lighthouse-testing-store.myshopify.com',
) => {
  const dontShow = getCookie('modal-expire');
  if (!dontShow) {
    const url = `${process.env.CAMERAFI_API_GATEWAY_URL}/public-api/v1/shopify/shops/${shop}/widget-live-event`;

    try {
      const result = await fetchLiveEventInfo(url);
      const liveEventInfo = result;
      if (liveEventInfo?.enabled !== false) {
        if (
          liveEventInfo?.templateId === 3 ||
          liveEventInfo?.templateId === 4 ||
          liveEventInfo?.templateId === 5
        ) {
          const result = await fetchLiveEventInfo(
            `${url}?includeDiscountInfo=true`,
          );
          const liveEventInfo = result as LiveInfosProps;

          createThemeElement(liveEventInfo, shop);
          return;
        }

        createThemeElement(liveEventInfo, shop);
      }
    } catch (e) {
      console.error(e);
    }
  }
};

if (process.env.NODE_ENV === 'development') {
  document.addEventListener('DOMContentLoaded', () => {
    modal(getShop()).then();
  });
} else {
  modal(getShop()).then();
}
